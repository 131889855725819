var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class: [!_vm.showWarning ? "box" : "box-invalid", {}],
        on: {
          click: function ($event) {
            return _vm.focusOnInput()
          },
        },
      },
      [
        _c("label", { staticClass: "label", attrs: { for: "tte" } }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
        _c("canvas", {
          staticStyle: { "border-style": "solid", display: "none" },
          attrs: { id: "canvas" },
        }),
        _c(
          "div",
          { staticClass: "controller" },
          [
            _c(
              "v-btn",
              {
                directives: [
                  {
                    name: "ripple",
                    rawName: "v-ripple",
                    value: false,
                    expression: "false",
                  },
                ],
                class: ["button-class"],
                attrs: { icon: "", disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleBold().run()
                  },
                },
              },
              [
                _c("img", {
                  class: [
                    "top-icon",
                    { "top-active": _vm.editor.isActive("bold") },
                  ],
                  attrs: { src: require("../assets/editor_icons/bold.svg") },
                }),
              ]
            ),
            _c(
              "v-btn",
              {
                class: ["button-class"],
                attrs: { icon: "", disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleItalic().run()
                  },
                },
              },
              [
                _c("img", {
                  class: [
                    "top-icon",
                    { "top-active": _vm.editor.isActive("italic") },
                  ],
                  attrs: { src: require("../assets/editor_icons/italic.svg") },
                }),
              ]
            ),
            _c(
              "v-btn",
              {
                class: ["button-class"],
                attrs: { icon: "", disabled: _vm.disabled },
                on: {
                  click: function ($event) {
                    _vm.editor.chain().focus().toggleUnderline().run()
                  },
                },
              },
              [
                _c("img", {
                  class: [
                    "top-icon",
                    { "top-active": _vm.editor.isActive("underline") },
                  ],
                  attrs: {
                    src: require("../assets/editor_icons/underline.svg"),
                  },
                }),
              ]
            ),
            _c("span", { staticStyle: { "padding-left": "15px" } }),
            _vm.allowListInsertion
              ? _c(
                  "v-btn",
                  {
                    class: ["button-class"],
                    attrs: { icon: "", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleOrderedList().run()
                      },
                    },
                  },
                  [
                    _c("img", {
                      class: [
                        "list-icon",
                        { "top-active": _vm.editor.isActive("orderedList") },
                      ],
                      attrs: {
                        src: require("../assets/editor_icons/list-ordered.svg"),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.allowListInsertion
              ? _c(
                  "v-btn",
                  {
                    class: ["button-class"],
                    attrs: { icon: "", disabled: _vm.disabled },
                    on: {
                      click: function ($event) {
                        _vm.editor.chain().focus().toggleBulletList().run()
                      },
                    },
                  },
                  [
                    _c("img", {
                      class: [
                        "list-icon",
                        { "top-active": _vm.editor.isActive("bulletList") },
                      ],
                      attrs: {
                        src: require("../assets/editor_icons/list-unordered.svg"),
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _vm.allowImageInsertion || _vm.allowMediaInsertion
              ? _c("image-input", {
                  class: ["button-class"],
                  attrs: {
                    "allowed-formats": ".gif, .jpg, .jpeg, .png, .svg",
                    rules: [],
                    "no-preview": true,
                  },
                  on: { input: _vm.addImage },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c("v-btn", { attrs: { icon: "", height: "10" } }, [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/editor_icons/photo.svg"),
                                  height: "20px",
                                },
                              }),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    380250330
                  ),
                  model: {
                    value: _vm.insertedImage,
                    callback: function ($$v) {
                      _vm.insertedImage = $$v
                    },
                    expression: "insertedImage",
                  },
                })
              : _vm._e(),
            _vm.allowVideoInsertion || _vm.allowMediaInsertion
              ? _c("video-input", {
                  class: ["button-class"],
                  attrs: {
                    id: "video-input",
                    "allowed-formats": ".mp4, .webm, .ogg",
                    rules: [],
                    "no-preview": true,
                  },
                  on: { input: _vm.addVideo },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c("v-btn", { attrs: { icon: "", height: "10" } }, [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/editor_icons/video-upload.svg"),
                                  height: "20px",
                                },
                              }),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    710063945
                  ),
                  model: {
                    value: _vm.insertedVideo,
                    callback: function ($$v) {
                      _vm.insertedVideo = $$v
                    },
                    expression: "insertedVideo",
                  },
                })
              : _vm._e(),
            _vm.allowAudioInsertion || _vm.allowMediaInsertion
              ? _c("audio-input", {
                  class: ["button-class"],
                  attrs: {
                    id: "audio-input",
                    "allowed-formats": ".mp3, .wav, .ogg",
                    rules: [],
                    "no-preview": true,
                  },
                  on: { input: _vm.addAudio },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function () {
                          return [
                            _c("v-btn", { attrs: { icon: "", height: "10" } }, [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/editor_icons/audio-upload.svg"),
                                  height: "20px",
                                },
                              }),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4097321230
                  ),
                  model: {
                    value: _vm.insertedAudio,
                    callback: function ($$v) {
                      _vm.insertedAudio = $$v
                    },
                    expression: "insertedAudio",
                  },
                })
              : _vm._e(),
            _c("span", { staticStyle: { "padding-left": "15px" } }),
            _vm.allowTableInsertion
              ? _c(
                  "v-menu",
                  {
                    attrs: { "offset-x": "", "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      class: ["button-class"],
                                      attrs: { icon: "" },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("img", {
                                    class: [
                                      "list-icon",
                                      { "top-active": _vm.tableMenuActive },
                                    ],
                                    attrs: {
                                      src: require("../assets/editor_icons/layout-grid-line.svg"),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3510149446
                    ),
                    model: {
                      value: _vm.tableMenuActive,
                      callback: function ($$v) {
                        _vm.tableMenuActive = $$v
                      },
                      expression: "tableMenuActive",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "interactive-table-menu" },
                      _vm._l(40, function (i) {
                        return _c("div", {
                          key: i,
                          class: _vm.iClass(i),
                          on: {
                            mouseover: function ($event) {
                              _vm.selected = i
                            },
                            mouseleave: function ($event) {
                              _vm.selected = -1
                            },
                            click: function ($event) {
                              return _vm.assignTableDim()
                            },
                          },
                        })
                      }),
                      0
                    ),
                    _c("div", { staticStyle: { "padding-bottom": "3%" } }),
                  ]
                )
              : _vm._e(),
            _vm.allowTableInsertion
              ? _c(
                  "v-menu",
                  {
                    staticStyle: { "background-color": "white" },
                    attrs: {
                      "offset-x": "",
                      "offset-y": "",
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.disabled,
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm.tableManipulationMenuActive
                                    ? _c("v-icon", [_vm._v("mdi-menu-up")])
                                    : _c("v-icon", [_vm._v("mdi-menu-down")]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2103702044
                    ),
                    model: {
                      value: _vm.tableManipulationMenuActive,
                      callback: function ($$v) {
                        _vm.tableManipulationMenuActive = $$v
                      },
                      expression: "tableManipulationMenuActive",
                    },
                  },
                  [
                    _c("div", { staticClass: "py-2 bg-white" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor
                              .can()
                              .addColumnBefore(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addColumnBefore().run()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/editor_icons/insert-column-left.svg"),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("insert column left")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor
                              .can()
                              .addColumnAfter(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addColumnAfter().run()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/editor_icons/insert-column-right.svg"),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("insert column right")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor.can().addRowBefore(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addRowBefore().run()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/editor_icons/insert-row-top.svg"),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("insert row above")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor.can().addRowAfter(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().addRowAfter().run()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/editor_icons/insert-row-bottom.svg"),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("insert row below")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor.can().deleteColumn(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().deleteColumn().run()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/editor_icons/delete-column.svg"),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("remove column")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor.can().deleteRow(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().deleteRow().run()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("../assets/editor_icons/delete-row.svg"),
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("remove row")]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "table-options d-flex align-center px-3 py-1",
                          class: {
                            "disabled-class": !_vm.editor.can().deleteTable(),
                          },
                          on: {
                            click: function ($event) {
                              _vm.editor.chain().focus().deleteTable().run()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { attrs: { size: "18", color: "red accented-3" } },
                            [_vm._v("mdi-delete")]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "margin-left": "4%",
                                "font-size": "90%",
                              },
                            },
                            [_vm._v("Remove Table")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._t("special-control", null, { editor: _vm.editor }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "text-area" },
          [
            _c("editor-content", {
              staticClass: "editor-style",
              class: { "disabled-editor": _vm.disabled },
              attrs: { id: "tte", editor: _vm.editor },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.showFormatMessage.all || _vm.showFormatMessage.any
      ? _c("div", { staticClass: "accepted-formats d-flex justify-end" }, [
          _vm._v(" Allowed formats: "),
          _vm.showFormatMessage.images
            ? _c("span", [_vm._v("image: .gif,.jpg,.jpeg,.png,.svg;")])
            : _vm._e(),
          _vm.showFormatMessage.videos
            ? _c("span", [_vm._v("video:.mp4,.webm,.ogg;")])
            : _vm._e(),
          _vm.showFormatMessage.audios
            ? _c("span", [_vm._v("audio:.mp3,.wav,.ogg")])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }