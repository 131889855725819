var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.image && _vm.image.imageURL && !_vm.noPreview
      ? _c(
          "div",
          { staticClass: "position-relative px-5" },
          [
            _c(
              "m-row",
              {
                staticStyle: { "max-width": "350px" },
                attrs: { justify: "end" },
              },
              [
                _vm.image.imageURL
                  ? _c(
                      "v-btn",
                      {
                        staticStyle: {
                          position: "absolute",
                          "z-index": "14",
                          "border-style": "solid",
                          "border-color": "#c7c7c7",
                          "background-color": "#c7c7c7",
                        },
                        attrs: { icon: "" },
                        on: { click: _vm.clearImage },
                      },
                      [_c("v-icon", [_vm._v("close")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "m-row",
              { attrs: { width: "600" } },
              [
                _vm.image.imageURL
                  ? _c("m-img", {
                      attrs: {
                        src: _vm.image.imageURL,
                        contain: "",
                        "max-width": _vm.width,
                        "max-height": _vm.height,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        on: {
          click: function ($event) {
            return _vm.launchFilePicker()
          },
        },
      },
      [_vm._t("activator")],
      2
    ),
    _c("input", {
      ref: "file",
      staticStyle: { display: "none" },
      attrs: { type: "file", name: _vm.name, accept: _vm.allowedFormats },
      on: {
        change: function ($event) {
          return _vm.onFileChange($event.target.name, $event.target.files)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }