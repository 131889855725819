<template>
  <div>
    <div
      :class="[!showWarning ? 'box' : 'box-invalid', {}]"
      @click="focusOnInput()"
    >
      <label class="label" for="tte">{{ label }}</label>
      <canvas id="canvas" style="border-style: solid; display: none;" />

      <div class="controller">
        <v-btn
          v-ripple="false"
          icon
          :class="['button-class']"
          :disabled="disabled"
          @click="
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          "
        >
          <img
            src="../assets/editor_icons/bold.svg"
            :class="['top-icon', { 'top-active': editor.isActive('bold') }]"
          />
        </v-btn>
        <v-btn
          icon
          :class="['button-class']"
          :disabled="disabled"
          @click="
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          "
        >
          <img
            src="../assets/editor_icons/italic.svg"
            :class="['top-icon', { 'top-active': editor.isActive('italic') }]"
          />
        </v-btn>
        <v-btn
          icon
          :class="['button-class']"
          :disabled="disabled"
          @click="
            editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          "
        >
          <img
            src="../assets/editor_icons/underline.svg"
            :class="[
              'top-icon',
              { 'top-active': editor.isActive('underline') }
            ]"
          />
        </v-btn>
        <span style="padding-left: 15px"></span>
        <v-btn
          v-if="allowListInsertion"
          icon
          :class="['button-class']"
          :disabled="disabled"
          @click="
            editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()
          "
        >
          <img
            src="../assets/editor_icons/list-ordered.svg"
            :class="[
              'list-icon',
              { 'top-active': editor.isActive('orderedList') }
            ]"
          />
        </v-btn>

        <v-btn
          v-if="allowListInsertion"
          icon
          :class="['button-class']"
          :disabled="disabled"
          @click="
            editor
              .chain()
              .focus()
              .toggleBulletList()
              .run()
          "
        >
          <img
            src="../assets/editor_icons/list-unordered.svg"
            :class="[
              'list-icon',
              { 'top-active': editor.isActive('bulletList') }
            ]"
          />
        </v-btn>

        <image-input
          v-if="allowImageInsertion || allowMediaInsertion"
          v-model="insertedImage"
          allowed-formats=".gif, .jpg, .jpeg, .png, .svg"
          :class="['button-class']"
          :rules="[]"
          :no-preview="true"
          @input="addImage"
        >
          <template v-slot:activator>
            <v-btn icon height="10">
              <img src="../assets/editor_icons/photo.svg" height="20px" />
            </v-btn>
          </template>
        </image-input>

        <video-input
          v-if="allowVideoInsertion || allowMediaInsertion"
          id="video-input"
          v-model="insertedVideo"
          allowed-formats=".mp4, .webm, .ogg"
          :class="['button-class']"
          :rules="[]"
          :no-preview="true"
          @input="addVideo"
        >
          <template v-slot:activator>
            <v-btn icon height="10">
              <img
                src="../assets/editor_icons/video-upload.svg"
                height="20px"
              />
            </v-btn>
          </template>
        </video-input>

        <audio-input
          v-if="allowAudioInsertion || allowMediaInsertion"
          id="audio-input"
          v-model="insertedAudio"
          allowed-formats=".mp3, .wav, .ogg"
          :class="['button-class']"
          :rules="[]"
          :no-preview="true"
          @input="addAudio"
        >
          <template v-slot:activator>
            <v-btn icon height="10">
              <img
                src="../assets/editor_icons/audio-upload.svg"
                height="20px"
              />
            </v-btn>
          </template>
        </audio-input>

        <span style="padding-left: 15px"></span>
        <v-menu
          v-if="allowTableInsertion"
          v-model="tableMenuActive"
          offset-x
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn icon :class="['button-class']" v-bind="attrs" v-on="on">
              <img
                src="../assets/editor_icons/layout-grid-line.svg"
                :class="['list-icon', { 'top-active': tableMenuActive }]"
              />
            </v-btn>
          </template>
          <div class="interactive-table-menu">
            <div
              v-for="i in 40"
              :key="i"
              :class="iClass(i)"
              @mouseover="selected = i"
              @mouseleave="selected = -1"
              @click="assignTableDim()"
            ></div>
          </div>
          <div style="padding-bottom: 3%"></div>
        </v-menu>
        <v-menu
          v-if="allowTableInsertion"
          v-model="tableManipulationMenuActive"
          offset-x
          offset-y
          min-width="200"
          style="background-color: white"
        >
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" :disabled="disabled" v-on="on">
              <v-icon v-if="tableManipulationMenuActive">mdi-menu-up</v-icon>
              <v-icon v-else>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <div class="py-2 bg-white">
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().addColumnBefore() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .addColumnBefore()
                  .run()
              "
            >
              <img src="../assets/editor_icons/insert-column-left.svg" />
              <span style="margin-left: 4%; font-size: 90%"
                >insert column left</span
              >
            </div>
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().addColumnAfter() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .addColumnAfter()
                  .run()
              "
            >
              <img src="../assets/editor_icons/insert-column-right.svg" />
              <span style="margin-left: 4%; font-size: 90%"
                >insert column right</span
              >
            </div>
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().addRowBefore() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .addRowBefore()
                  .run()
              "
            >
              <img src="../assets/editor_icons/insert-row-top.svg" />
              <span style="margin-left: 4%; font-size: 90%"
                >insert row above</span
              >
            </div>
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().addRowAfter() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .addRowAfter()
                  .run()
              "
            >
              <img src="../assets/editor_icons/insert-row-bottom.svg" />
              <span style="margin-left: 4%; font-size: 90%"
                >insert row below</span
              >
            </div>
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().deleteColumn() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .deleteColumn()
                  .run()
              "
            >
              <img src="../assets/editor_icons/delete-column.svg" />
              <span style="margin-left: 4%; font-size: 90%">remove column</span>
            </div>
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().deleteRow() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .deleteRow()
                  .run()
              "
            >
              <img src="../assets/editor_icons/delete-row.svg" />
              <span style="margin-left: 4%; font-size: 90%">remove row</span>
            </div>
            <div
              class="table-options d-flex align-center px-3 py-1"
              :class="{ 'disabled-class': !editor.can().deleteTable() }"
              @click="
                editor
                  .chain()
                  .focus()
                  .deleteTable()
                  .run()
              "
            >
              <v-icon size="18" color="red accented-3">mdi-delete</v-icon>
              <span style="margin-left: 4%; font-size: 90%">Remove Table</span>
            </div>
          </div>
        </v-menu>
        <slot name="special-control" :editor="editor"></slot>
      </div>
      <div class="text-area">
        <editor-content
          id="tte"
          class="editor-style"
          :class="{ 'disabled-editor': disabled }"
          :editor="editor"
        />
      </div>
    </div>
    <div
      v-if="showFormatMessage.all || showFormatMessage.any"
      class="accepted-formats d-flex justify-end"
    >
      Allowed formats:
      <span v-if="showFormatMessage.images"
        >image: .gif,.jpg,.jpeg,.png,.svg;</span
      >
      <span v-if="showFormatMessage.videos">video:.mp4,.webm,.ogg;</span>
      <span v-if="showFormatMessage.audios">audio:.mp3,.wav,.ogg</span>
    </div>
    <!-- Block for handling error messages will be implemented later -->
    <!--    <div style="position: absolute; bottom: -30px; height: 30px">-->
    <!--      <v-slide-y-transition>-->
    <!--        <span v-show="error.messages?.length > 0" class="px-3 mb-2 decline&#45;&#45;text xl:text-sm lg:text-sm md:text-sm sm:text-sm">{{}}</span>-->
    <!--      </v-slide-y-transition>-->
    <!--    </div>-->
  </div>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Image from "@tiptap/extension-image";
import { Editor, EditorContent } from "@tiptap/vue-2";

import ImageInput from "~ecf/components/ImageInput";
import VideoInput from "~ecf/components/VideoInput";
import AudioInput from "~ecf/components/AudioInput";

export default {
  components: {
    ImageInput,
    VideoInput,
    AudioInput,
    EditorContent
  },

  props: {
    allowListInsertion: {
      type: Boolean,
      default: false
    },
    allowMediaInsertion: {
      type: Boolean,
      default: false
    },
    allowImageInsertion: {
      type: Boolean,
      default: false
    },
    allowVideoInsertion: {
      type: Boolean,
      default: false
    },
    allowAudioInsertion: {
      type: Boolean,
      default: false
    },
    allowTableInsertion: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    placeHolder: {
      type: String,
      default: "placeholder text...."
    },
    isValid: {
      type: Boolean,
      default: true
    },
    rules: { type: Array, default: () => [] }
  },

  data() {
    return {
      valueWithMediaBlobUrls: undefined,
      insertedFiles: {},
      thumbnailVsActualBlobUrl: {},
      videoThumbnailHeight: 200,
      insertedVideo: undefined,
      insertedAudio: undefined,
      videoThumbnails: [],
      insertedImage: undefined,
      vidThumbnail: undefined,
      editor: null,
      formatOption: {},
      tableMenuActive: false,
      tableManipulationMenuActive: false,
      selected: -1,
      previouslyFocused: false,
      previouslyEdited: false,
      error: {}
    };
  },

  computed: {
    showWarning() {
      return !this.isValid && this.previouslyEdited && this.previouslyEdited;
    },
    showFormatMessage() {
      return {
        all: this.allowMediaInsertion,
        any:
          this.allowAudioInsertion ||
          this.allowImageInsertion ||
          this.allowVideoInsertion,
        images: this.allowMediaInsertion || this.allowImageInsertion,
        videos: this.allowMediaInsertion || this.allowVideoInsertion,
        audios: this.allowMediaInsertion || this.allowAudioInsertion
      };
    }
  },

  watch: {
    value(val) {
      this.previouslyEdited = true;
      this.valueWithMediaBlobUrls = this.replaceWithActualBlobUUID(val);
      this.$emit("content-update", {
        valueWithMediaBlobUrls: this.valueWithMediaBlobUrls,
        insertedFiles: this.insertedFiles
      });
    },

    insertedImage(value) {
      this.editor
        .chain()
        .focus()
        .setImage({ src: value.imageURL })
        .run();
    }
  },

  created() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Placeholder.configure({
          placeholder: this.placeHolder
        }),
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell,
        Image
      ],
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    });
    if (this.value === undefined || this.value?.length === 0)
      this.isValid = true;
  },
  beforeDestroy() {
    this.editor.destroy();
  },

  methods: {
    validate() {
      this.$set(
        this.error,
        "messages",
        this.rules.map(rule => rule()).filter(item => item !== true)
      );
    },
    focusOnInput() {
      if (this.previouslyFocused) this.previouslyFocused = true;
      this.$el.querySelector(".ProseMirror").focus();
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    replaceWithActualBlobUUID(val) {
      let newVal = val;
      let tagUpdated = "";
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(newVal, "text/html");
      const htmlElements = htmlDoc.querySelectorAll(
        "h1,h2,h3,h4,h5,h6,div,p,img"
      );
      let counter = 0;
      while (counter < htmlElements.length) {
        let outerHTML = htmlElements[counter].outerHTML;
        let htmlElement = htmlElements[counter];
        if (htmlElement.tagName.toLowerCase() === "img") {
          let thumbnailBlobUrl = htmlElement.src;
          if (htmlElement.src in this.thumbnailVsActualBlobUrl) {
            let insertedFile = this.thumbnailVsActualBlobUrl[thumbnailBlobUrl];
            let replaceWithTag = insertedFile["type"];
            outerHTML = outerHTML.replace(
              htmlElement.tagName.toLowerCase(),
              replaceWithTag
            );
            outerHTML = outerHTML.replace(
              thumbnailBlobUrl,
              insertedFile["url"] + "." + insertedFile["format"]
            );
          } else {
            let imageFileUrl = htmlElement.src.split("/").reverse()[0];
            let insertedFile = this.insertedFiles[imageFileUrl];
            outerHTML = outerHTML.replace(
              htmlElement.src,
              htmlElement.src.split("/").reverse()[0] +
                "." +
                insertedFile["format"]
            );
          }
        }
        tagUpdated += outerHTML;
        counter++;
      }
      return tagUpdated;
    },
    canvasToBlobUrl(canvas) {
      let img_b64 = canvas.toDataURL("image/jpeg");

      let thumbnailBlob = this.dataURItoBlob(img_b64);
      return URL.createObjectURL(thumbnailBlob);
    },
    async generateVideoThumbnail(inputVideoBlobURL) {
      let canvas = document.getElementById("canvas");

      let playButton = document.createElement("img");
      playButton.src =
        "https://img.icons8.com/glyph-neue/64/228BE6/circled-play--v1.png";
      playButton.crossOrigin = "anonymous"; // to avoid cors
      let video = document.createElement("video");
      video.src = inputVideoBlobURL;

      await this.sleep(500); // necessary sleep

      let widthToHeightRatio = video.videoWidth / video.videoHeight;
      let height = this.videoThumbnailHeight;
      let width = this.videoThumbnailHeight * widthToHeightRatio;
      canvas.height = height;
      canvas.width = width;

      let context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, width, height);
      context.drawImage(playButton, width / 2.4, height / 3, 64, 64);

      let thumbnailBlobUrl = this.canvasToBlobUrl(canvas);

      let videoFileUUID = inputVideoBlobURL.split("/").reverse()[0];
      this.thumbnailVsActualBlobUrl[thumbnailBlobUrl] = {
        type: "video",
        format: this.insertedFiles[videoFileUUID]["format"],
        url: inputVideoBlobURL.split("/").reverse()[0]
      };
      this.insertVideoThumbnail(thumbnailBlobUrl);
    },
    insertVideoThumbnail(blobURL) {
      this.editor.commands.insertContent(`<img src=${blobURL}>`);
    },
    async insertAudioThumbnail(audioFileName, audioFileBlobUrl) {
      // setting up canvas
      let canvas = document.getElementById("canvas");
      canvas.width = 300;
      canvas.height = 160;

      // setting up canvas context
      let context = canvas.getContext("2d");
      context.font = "14pt";
      context.fillText("<" + audioFileName + ">", 64, 85);

      // creating an audio symbol from the icon url
      let audioSymbol = document.createElement("img");
      audioSymbol.src = "https://img.icons8.com/material/24/medium-volume.png";
      audioSymbol.crossOrigin = "anonymous"; // to avoid cors

      await this.sleep(500); // necessary sleep so that things don't malfunction
      context.drawImage(audioSymbol, 40, 70, 24, 24);

      let img_b64 = canvas.toDataURL("image/png");
      let thumbnailBlob = this.dataURItoBlob(img_b64);
      let thumbnailBlobUrl = URL.createObjectURL(thumbnailBlob);

      let audioFileUUID = audioFileBlobUrl.split("/").reverse()[0]; // getting just the UUID of the audio file
      this.thumbnailVsActualBlobUrl[thumbnailBlobUrl] = {
        type: "audio",
        format: this.insertedFiles[audioFileUUID]["format"],
        url: audioFileUUID
      };
      this.editor.commands.insertContent(`<img src=${thumbnailBlobUrl}>`);
    },

    dataURItoBlob(dataURI) {
      // can be moved to global helpers, but keeping it here as there are no invocations in other than this file
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      let mimeString = dataURI
        .split(",")[0]
        .split(":")[1]
        .split(";")[0];

      // write the bytes of the string to a typed array
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    },

    dataURLtoFile(dataUrl, filename) {
      // can be moved to global helpers, but keeping it here as there are no invocations in other than this file
      let arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    addImage() {
      if (this.insertedImage) {
        this.editor
          .chain()
          .focus()
          .setImage({ src: this.insertedImage.imageURL })
          .run();
        this.insertedFiles[
          this.insertedImage.imageURL.split("/").reverse()[0]
        ] = {
          type: "image",
          format: this.insertedImage.file.name.split(".").pop(),
          name: this.insertedImage.file.name,
          file: this.insertedImage.file
        };
        this.insertedImage = undefined;
      }
    },
    async addVideo(value) {
      let videoBlobURL = value.videoURL;
      if (this.insertedVideo) {
        this.insertedFiles[
          this.insertedVideo.videoURL.split("/").reverse()[0]
        ] = {
          type: "video",
          format: this.insertedVideo.file.name.split(".").pop(),
          name: this.insertedVideo.file.name,
          file: this.insertedVideo.file
        };
        this.insertedVideo = undefined;
        await this.generateVideoThumbnail(videoBlobURL);
      }
    },
    async addAudio(value) {
      let audioBlobURL = value.audioURL;
      if (this.insertedAudio) {
        this.insertedFiles[
          this.insertedAudio.audioURL.split("/").reverse()[0]
        ] = {
          type: "audio",
          format: this.insertedAudio.file.name.split(".").pop(),
          name: this.insertedAudio.file.name,
          file: this.insertedAudio.file
        };
        await this.insertAudioThumbnail(
          this.insertedAudio.file.name,
          audioBlobURL
        );
        this.insertedAudio = undefined;
      }
    },
    iClass(index) {
      if (this.selected === index) {
        return "current-selected";
      } else {
        index -= 1;
        let s = this.selected - 1;
        let col = index % 8;
        let row = (index - col) / 8;
        let iCol = s % 8;
        let iRow = (s - iCol) / 8;
        if (row > iRow || col > iCol) return "interactive-selectors";
        else return "also-selected";
      }
    },
    assignTableDim() {
      this.tableMenuActive = false;
      let col = (this.selected - 1) % 8;
      let row = (this.selected - 1 - col) / 8;
      this.editor
        .chain()
        .focus()
        .insertTable({ rows: row + 1, cols: col + 1, withHeaderRow: true })
        .run();
    }
  }
};
</script>

<style scoped lang="scss">
.text-area {
  padding: 0px 12px;
}

.new-class {
  height: 30px !important;
  background-color: blue;
}

.vid-thumb {
  position: relative;
}

.overlay-icon {
  position: absolute;
  height: 20px !important;
  background: white;
  bottom: 0;
  left: 0;
}

.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}

#video-input {
  max-width: 100px !important;
}

.box,
.box-invalid {
  width: 100%;
  border: 1px solid #bcbcbc;
  min-height: 192px;
  position: relative;
}

.label {
  font-size: 12px;
  color: #646464;
  padding: 4px 6px 0px;
  background-color: white;
  position: absolute;
  top: -15px;
  left: 5px;
}

.controller {
  overflow: auto;
  height: 42px;
  border-bottom: 1px solid #bcbcbc;
  display: flex;
  align-items: center;
  padding: 0px 8px;
}

.button-class {
  height: 24px !important;
  width: 28px !important;
  min-width: 28px !important;
  margin-right: 2px;
}

.top-icon {
  height: 19px;
}

.list-icon {
  height: 17px;
}

.top-active {
  filter: invert(23%) sepia(96%) saturate(2271%) hue-rotate(198deg)
    brightness(89%) contrast(101%);
}

.editor-style::v-deep .ProseMirror:focus {
  outline: none;
}

.editor-style::v-deep .ProseMirror {
  padding: 12px 15px;
  min-height: 150px;

  table {
    border-collapse: collapse;

    td,
    th {
      border: 2px solid black;
      min-width: 50px;
      padding: 5px 10px;
    }
  }

  p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
  }

  img {
    height: 160px;
  }

  img.ProseMirror-selectednode {
    height: 160px;
  }
}

.box:focus-within {
  border: 1px solid $primary;
  outline: 1px solid $primary;

  .label {
    color: $primary;
  }

  .controller {
    border-color: $primary;
  }
}

.box-invalid:not(:focus-within),
.box-invalid:focus-within {
  border: 1px solid $decline;

  .label {
    color: $decline;
  }

  .controller {
    border-color: $decline;
  }
}

.interactive-table-menu {
  display: grid;
  grid-template: repeat(5, 16px) / repeat(8, 16px);
  grid-gap: 3px;
  padding: 5px;
  background-color: white;

  .interactive-selectors {
    pointer-events: fill;
    border: 1px solid #d4d4d4;
  }

  .current-selected {
    background-color: $primary;
  }

  .also-selected {
    background-color: $primary;
    border: 1px solid #d4d4d4;
  }
}

.table-options {
  cursor: pointer;
}

.table-options:hover {
  background-color: transparentize($grey, 0.87);
}

.disabled-class {
  pointer-events: none;
}

.disabled-editor::v-deep .ProseMirror {
  pointer-events: none;
}

.accepted-formats {
  font-size: 12px !important;
  color: grey;

  span {
    margin-left: 4px;
  }
}
</style>
