<template>
  <div>
    <!--    <slot name="placeholder" v-if="!image"></slot>-->
    <div
      v-if="image && image.imageURL && !noPreview"
      class="position-relative px-5"
    >
      <m-row style="max-width: 350px" justify="end">
        <v-btn
          v-if="image.imageURL"
          icon
          style="
            position: absolute;
            z-index: 14;
            border-style: solid;
            border-color: #c7c7c7;
            background-color: #c7c7c7;
          "
          @click="clearImage"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </m-row>
      <m-row width="600">
        <m-img
          v-if="image.imageURL"
          :src="image.imageURL"
          contain
          :max-width="width"
          :max-height="height"
        ></m-img>
      </m-row>
    </div>
    <div @click="launchFilePicker()">
      <slot name="activator"></slot>
    </div>
    <!-- image input: style is set to hidden and assigned a ref so that it can be triggered -->
    <input
      ref="file"
      type="file"
      :name="name"
      :accept="allowedFormats"
      style="display: none"
      @change="onFileChange($event.target.name, $event.target.files)"
    />
  </div>
</template>

<script>
export default {
  name: "ImageInput",
  model: {
    prop: "image"
  },
  props: {
    image: Object,
    name: {
      type: String,
      default: "file"
    },
    allowedFormats: {
      type: String,
      default: "image/*"
    },
    width: {
      type: [Number, String],
      default: 300
    },
    height: {
      type: [Number, String],
      default: 300
    },
    maxSize: {
      type: [Number, String],
      default: 1024
    },
    rules: {
      type: Array
    },
    noPreview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange(fieldName, file) {
      let imageFile = file[0];
      if (file.length > 0) {
        for (let item of this.rules) {
          if (item(imageFile) !== true) {
            this.$emit("validation", item(imageFile));
            this.$emit("input", { [this.name]: null, imageURL: null });
            return true;
          }
        }
        this.$emit("validation", undefined);
        // Append file into FormData and turn file into image URL
        let imageURL = URL.createObjectURL(imageFile);
        // Emit the FormData and image URL to the parent component
        this.$emit("input", { [this.name]: imageFile, imageURL });
      } else {
        this.$emit("input", { [this.name]: null, imageURL: null });
      }
    },
    clearImage() {
      this.$emit("input", { [this.name]: null, imageURL: null });
    }
  }
};
</script>
